import {Image, Switch, Table} from "antd";
import useUpdateActivityPayment from "../../hooks/payments/useUpdateActivityPayment";
import React from "react";
import useUpdateLogoPayment from "../../hooks/payments/useUpdateLogoPayment";
import LogoUploader from "../Uploader/LogoUploader";

const TablePayment = ({ data, reload }) => {

    const {updateLogo}  = useUpdateLogoPayment();
    const handleUpdateLogo = (data, id) => {
        updateLogo(data, id).then(() => reload());
    };
    const columns = [
        {
            title: 'Aktywny',
            dataIndex: 'active',
            key: 'active',
            render: (row) => <Switch onClick={(e) => updateActivity(row, e)} checked={row}/>
        },
        {
            title: 'Logo',
            key: 'logo',
            dataIndex: '',
            render: (row) => <Image src={row.logo} style={{width: '40px', height: '40px', objectFit: 'contain'}} />
        },
        {
            title: 'Nazwa',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Utworzono',
            key: 'createdAt',
            dataIndex: 'createdAt'
        },
        {
            title: '',
            key: 'action',
            render: row => <LogoUploader
                update={handleUpdateLogo}
                item={row}
                reload={reload}
            />
        },
    ];

  const { update } = useUpdateActivityPayment();

  const updateActivity = (item, e) => {
    update(item.id, e).then(() => reload());
  };
  return <Table columns={columns} pagination={false} dataSource={data.map((it, index) => {
      return {
          ...it, key: index
      }
  })}/>
};

export default TablePayment;