import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useUpdateActivityPayment = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const update = async (id: string, isActive: boolean): Promise<any> => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.put(
        "/admin/payment/activity/" + id,
        { isActive: isActive }
      );

      if (data.id) {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  return { update, isLoading };
};

export default useUpdateActivityPayment;
